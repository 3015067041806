<template>
  <div class="section--desc mt-5">
    <div class="subtitle--text">{{ $t('addListing.description') }}</div>
    <hr />
    <div>
      <p v-if="description !== null" v-html="description"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
    },
  },
};
</script>

<style></style>
